import { createTheme } from '@mui/material';

const lightTheme = {
  fontLexendTera: 'Lexend Tera',
  fontBaiJamjuree: 'Bai Jamjuree',
  fontMontserrat: 'Montserrat',

  colorElectricRed: '#E10600',
  colorFashionFuchsia: '#E10098',
  colorDarkOrchid: '#963CBD',
  colorIslamicGreen: '#8C0077',
  colorMaximumBlueGreen: '#2CCCD3',
  colorMediumTurquoise: '#3FCFD5',
  colorVividYellow: '#FCE300',
  colorVividGamboge: '#FF9800',
  colorOrangePeel: '#FF9900',
  colorIrishGreen: '#008C15',
  colorKellyGreen: '#00B907',
  colorSpanishGray: '#999999',
  colorWhite: '#FFFFFF',
  colorBlack: '#000000',
  colorLightGray: '#B4B4B4',
  colorGray: '#646464',
  colorAlto: '#D8D8D8',

  backgroundColorGray: 'rgba(0, 0, 0, 0.6)',
  backgroundColorBlack: 'rgba(0, 0, 0, 1)',
  backgroundColorWhite: 'rgba(255, 255, 255, 1)',
  backgroundColorDisabled: 'rgba(153, 153, 153, 1)',
  backgroundColorAlabaster: '#F9FAFC',

  borderColor: '#313131',
} as const;

// export type Theme = typeof lightTheme

const themes = {
  light: lightTheme,
  //add theme if customer like
};

let themeMaterial = createTheme({
  zIndex: {
    appBar: 9999,
  },
  components: {
    MuiButton: { styleOverrides: { root: { textTransform: 'unset' } } },
  },
  palette: {
    primary: {
      main: lightTheme.colorMaximumBlueGreen,
      contrastText: lightTheme.colorWhite,
    },
    secondary: {
      main: lightTheme.colorIrishGreen,
    },
  },
  typography: {
    fontFamily: [
      'Montserrat',
      'Lexend Tera',
      'Bai Jamjuree',
      'SF ProDisplay',
      'Opens Sans',
      'serif',
    ].join(','),
  },
});

themeMaterial = createTheme(themeMaterial, {
  // Custom colors created with augmentColor go here
  palette: {
    gray: themeMaterial.palette.augmentColor({
      color: {
        main: lightTheme.colorIrishGreen,
      },
      name: 'gray',
    }),
    orangePeel: themeMaterial.palette.augmentColor({
      color: {
        main: lightTheme.colorOrangePeel,
      },
      name: 'orangePeel',
    }),
    kellyGreen: themeMaterial.palette.augmentColor({
      color: {
        main: lightTheme.colorKellyGreen,
      },
      name: 'kellyGreen',
    }),
    electricRed: themeMaterial.palette.augmentColor({
      color: {
        main: lightTheme.colorElectricRed,
      },
      name: 'electricRed',
    }),
  },
});

export { themeMaterial, themes };

declare module '@mui/material/styles' {
  interface Palette {
    gray: Palette['primary'];
    orangePeel: Palette['primary'];
    kellyGreen: Palette['primary'];
    electricRed: Palette['primary'];
  }

  interface PaletteOptions {
    gray?: PaletteOptions['primary'];
    orangePeel?: PaletteOptions['primary'];
    kellyGreen?: PaletteOptions['primary'];
    electricRed?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    gray: true;
    orangePeel: true;
    kellyGreen: true;
    electricRed: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    gray: true;
    orangePeel: true;
    kellyGreen: true;
    electricRed: true;
  }
}
