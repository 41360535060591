import {
  CartIcon,
  CashIcon,
  ChainIcon,
  ClassicMouseIcon,
  EyeIcon,
  ShoppingCartIcon,
  TargetIcon,
  WalletIcon,
  WatchIcon,
} from '../components/Icons';

const commonConstants = {
  SOMETHING_WENT_WRONG: 'Something went wrong.',
  EXPIRED_SESSION_MESSAGE: 'Your session has expired. Please Sign in',
  ADMIN: 'admin',
  DIRECTION: {
    ASC: 'asc',
    DESC: 'desc',
  },
  ASSIGN_CATEGORY_DEFAULT_QUERY_PARAMS: {
    page: 1,
    limit: 10,
    keyword: '',
    interest: null,
    category: null,
    subCategory: null,
  },
  MANAGING_VENDOR_DASHBOARD_DEFAULT_QUERY_PARAMS: {
    page: 1,
    limit: 10,
    keyword: '',
    direction: 'asc',
    status: '',
  },
  VENDORS_DEFAULT_QUERY_PARAMS: {
    page: 1,
    limit: 10,
  },
  REFERRALS_DEFAULT_QUERY_PARAMS: {
    page: 1,
    limit: 10,
  },
  REFERRERS_DEFAULT_QUERY_PARAMS: {
    page: 1,
    limit: 10,
  },
  API_NAME: {
    ADMIN: 'admin',
    USER: 'user',
    PRODUCT: 'product',
  },
  API_PATH: {
    INFO: '/info',
    VENDOR: '/vendor',
    CATEGORIES: '/categories',
    ITEM_BY_CATEGORY: '/item-by-category/',
    ASSIGN_CATEGORY_TO_PRODUCT: '/assign-category-to-product/',
  },
  DATE_FORMAT: {
    DD_MM_YY: 'DD/MM/YY',
  },
  VENDOR_STATUSES: [
    { label: 'Awaiting Approval', value: 'pending' },
    { label: 'Approved', value: 'approved' },
  ],
  MAPPING_TOTAL_PERFORMANCE_ICON: {
    total_link_purchases: <ShoppingCartIcon />,
    total_referral_sales: <CashIcon />,
    conversions: <CartIcon />,
    conversions_rate: <TargetIcon />,
    total_impressions: <EyeIcon />,
    total_link_clicks: <ClassicMouseIcon />,
    active_referrals: <ChainIcon />,
    total_referee_profit: <WalletIcon />,
    duration: <WatchIcon />,
  } as const,
  /* millisecond */
  STALE_TIME: {
    MIN_1: 1000 * 60,
    SEC_20: 1000 * 20,
  } as const,
  VENDOR_STATUS: {
    APPROVED: 'approved',
    PENDING: 'pending',
    DENIED: 'denied',
  } as const,
};

export default commonConstants;
